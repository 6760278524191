<template>
  <div>
    <search-TenantMiniProgramUsers @submit="submitSearch" />
    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="fetchData"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import { findTenantMiniProgramUsers } from '@/api/mini_program_user'
import SearchTenantMiniProgramUsers from '@/views/mini_program_users/tenant/Search'
import Pagination from '@/components/Pagination'

export default {
  name: 'TenantMiniProgramUsersList',
  components: {
    Pagination,
    SearchTenantMiniProgramUsers
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      previewVisible: false,
      pagination: {
        total_count: 0
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '用户名',
          dataIndex: 'name'
        },
        {
          title: '昵称',
          dataIndex: 'nick_name'
        },
        {
          title: '联系电话',
          dataIndex: 'phone_number'
        }
      ]
    }
  },
  methods: {
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findTenantMiniProgramUsers(this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
